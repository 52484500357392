<template>
  <div class="col-md-12 default">
    <type-property />
  </div>
</template>

<script>
import typeProperty from "./TypeProperty.vue";

export default {
  components: {
   typeProperty
  },
  props: ["type"],
  data() {
    return {
      
    };
  },
  computed: {},
  created() {
  },
  mounted() {
  },

  methods: {
   
  },
};
</script>
