<template>
  <div class="col-xl-12">
    <div class="row">
      <div class="col-lg-12">
        <div class="TypePropertyList bg-white">
          <div class="TypePropertyListTitle">
            □ {{$t('설비 유형')}}
          </div>
          <div class="TypePropertyListTable">
            <type-property-table :data="items" @selectType="selectTypeEmit"></type-property-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import backEndApi from "../../../api/backEndApi";
import typePropertyTable from "./TypePropertyTable.vue";

export default {
  name: "TypePropertyList",
  components: {
    typePropertyTable,
  },
  data() {
    return {
      items: null,
    };
  },
  created() {
    this.getEquipType();
  },
  methods: {
    async getEquipType() {
      // backEndApi.equipType.getEquipTypeList().then(({ data }) => {
      //   this.items = data;
      // });
      const { data } = await backEndApi.equipType.getEquipTypeList();
      this.items = data;
    },
    selectTypeEmit(value){
      this.$emit('selectType', value);
    }
  },
};
</script>

<style lang="scss" scoped>
.TypePropertyList {
  border: 1px solid #313131;
  border-radius: 5px;
  .TypePropertyListTitle {
    padding: 15px;
    font-size: 15px;
    font-weight: bold;
  }

  .TypePropertyListTable{
    padding: 15px;
    margin: 5px;
    border: 1px solid #000000;
  }
}
</style>
