<template>
	<div class="col-xl-12">
		<div class="row">
			<div class="col-lg-12">
				<div class="pull-left">
					<h3>{{ $t("시스템 관리") }} > {{ $t("계통 및 설비") }} > {{ $t("설비 수집항목 설정") }}</h3>
				</div>
			</div>
		</div>

		<hr class="mt-3 mb-3" />

		<div class="row">
			<div class="col-lg-4">
				<type-property-list @selectType="selectTypeEmit"></type-property-list>
			</div>

			<div class="col-lg-8">
				<type-property-detail :data="items"></type-property-detail>
			</div>
		</div>
	</div>
</template>

<script>
	// import backEndApi from "../../../../api/backEndApi";

	import typePropertyList from "./TypePropertyList.vue";
	import typePropertyDetail from "./TypePropertyDetail.vue";

	export default {
		props: [],
		components: {
			typePropertyList,
			typePropertyDetail,
		},

		data() {
			return {
				items: null,
			};
		},
		created() {},
		mounted() {},
		methods: {
			selectTypeEmit(value) {
				this.items = value;
			},

			newModal() {
				this.$refs.newModal.show();
			},
			hide() {
				this.$refs.newModal.hide();
			},
			reSearch() {
				this.$refs.search.reSearch();
			},
		},
	};
</script>

<style scoped></style>
