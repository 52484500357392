<template>
	<div class="TypePropertyDetailTable">
		<b-table
			head-variant="dark"
			show-empty
			hover
			responsive
			ref="TypePropertyDetailTable"
			:style="'min-width:100%;'"
			:class="'text-center mb-0 rounded'"
			:items="tableData"
			:fields="tableField"
			sticky-header="690px"
			:empty-text="`등록된 내용이 없습니다.`"
			table-variant="light"
			select-mode="multi"
			selectable
			@row-clicked="onRowDetail"
		>
			<!-- @row-clicked="onRowClicked" -->
			<!-- <template #empty="">
        <h4>{{ '조회자료 없음' }}</h4>
      </template> -->

			<!-- <template v-slot:cell(sesnDvsnCode)="row"  v-for="(sesnDvsnCode, index) in tableCode.sesnDvsnCodes" :key="index" :value="sesnDvsnCode">

      </template> -->
			<template #cell(checked)="row">
				<b-form-checkbox v-model="row.item.checked" @change="isCheckService(row.item.checked, row)"></b-form-checkbox>
			</template>

			<template #cell(equipPropCode)="row">
				<!-- <b-form-input v-model="row.item.equipPropCode" :disabled="row.item.isServer"></b-form-input> -->
				<valid-input
					:vid="`항목코드${row.index}`"
          :vidName="'항목코드'"
					:inputType="'text'"
					:inputValue.sync="row.item.equipPropCode"
					:rules="rules.EQUIP_SYSTEM_COLLECT_CODE_RULE"
					:errorMessage="validMessage.EQUIP_SYSTEM_COLLECT_CODE_VALID_MESSAGE"
					:disabled="row.item.isNew !== true"
					:NonStatus="true"
					:notRules="!row.item.checked"
				></valid-input>
			</template>

			<template #cell(equipPropName)="row">
				<!-- <b-form-input v-model="row.item.equipPropName"></b-form-input> -->
				<valid-input
					:vid="`항목명${row.index}`"
          :vidName="'항목명'"
					:inputType="'text'"
					:inputValue.sync="row.item.equipPropName"
					:rules="rules.EQUIP_SYSTEM_COLLECT_NAME_RULE"
					:errorMessage="validMessage.EQUIP_SYSTEM_COLLECT_NAME_VALID_MESSAGE"
					:NonStatus="true"
					:notRules="!row.item.checked"
				></valid-input>
			</template>

			<template #cell(ctrlOptCode)="row">
				<!-- <p>{{row}}</p> -->
				<valid-select
					:vid="`제어옵션${row.index}`"
          :vidName="'제어옵션'"
					:inputValue.sync="row.item.ctrlOptCode"
					:selectOption="ctrlOptions"
					:setValueCode="'value'"
					:setValueCodeName="'text'"
					:rules="rules.EQUIP_SYSTEM_COLLECT_CTRL_OPTION_RULE"
					:errorMessage="validMessage.EQUIP_SYSTEM_COLLECT_CTRL_OPTION_VALID_MESSAGE"
					:NonStatus="true"
          :notRules="!row.item.checked"
				></valid-select>
				<!-- <b-form-select v-model="row.item.ctrlOptCode" :options="ctrlOptions"></b-form-select> -->
			</template>

			<template #cell(enabled)="row">
				<valid-select
					:vid="`사용여부${row.index}`"
          :vidName="'사용여부'"
					:inputValue.sync="row.item.enabled"
					:selectOption="tableUsegeOptions"
					:setValueCode="'value'"
					:setValueCodeName="'text'"
					:rules="rules.EQUIP_SYSTEM_COLLECT_IS_USE_RULE"
					:errorMessage="validMessage.EQUIP_SYSTEM_COLLECT_IS_USE_VALID_MESSAGE"
					:NonStatus="true"
          :notRules="!row.item.checked"
				></valid-select>

				<!-- <b-form-select v-model="row.item.enabled" :options="tableUsegeOptions"></b-form-select> -->
			</template>

			<template #cell(sortOrder)="row">
				<!-- <b-form-input type="number" v-model="row.item.sortOrder"></b-form-input> -->
				<valid-input
					:vid="`정렬순서${row.index}`"
          :vidName="'정렬순서'"
					:inputType="'number'"
					:inputValue.sync="row.item.sortOrder"
					:errorMessage="validMessage.COMMON_NUMBER_LENGTH_VALID_MESSAGE(1, 999)"
					:rules="rules.EQUIP_SYSTEM_COLLECT_SORT_RULE"
					:NonStatus="true"
          :notRules="!row.item.checked"
				></valid-input>
			</template>

			<template #cell(propDesc)="row">
				<!-- <b-form-input v-model="row.item.propDesc"></b-form-input> -->
				<valid-input
					:vid="`비고${row.index}`"
          :vidName="'비고'"
					:inputType="'text'"
					:inputValue.sync="row.item.propDesc"
					:errorMessage="validMessage.EQUIP_SYSTEM_COLLECT_NAME_VALID_MESSAGE"
					:rules="rules.EQUIP_SYSTEM_COLLECT_ETC_RULE"
					:NonStatus="true"
          :notRules="!row.item.checked"
				></valid-input>
			</template>

			<template #empty="scope">
				<div>
					{{ scope.emptyText }}
				</div>
			</template>
			<!--
      <template #cell(holiTypeCode)="row">
        <select class="form-control" ref="holiTypeCode" v-model="row.item.holiTypeCode">
          <option v-for="(code, index) in holiTypeCodeList" :key="index" :value="code.smallCode">
            {{ code.smallCodeName }}
          </option>
        </select>
      </template>

      <template #cell(baseDate)="row">
        <b-form-datepicker :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
          viewMode= 'days' locale= 'ko' 
          v-model="row.item.baseDate" class="mb-2 text-left">
        </b-form-datepicker>
      </template>

      <template #cell(holiDesc)="row">
        <input class="form-control text-left" v-model= "row.item.holiDesc"/> 
      </template>

      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span class="align-middle" aria-hidden="true">&check;</span>
          <span class="sr-only align-middle">Selected</span>
        </template>
        <template v-else>
          <span class="align-middle" aria-hidden="true">&nbsp;</span>
          <span class="sr-only align-middle">Not selected</span>
        </template>
      </template>       -->
		</b-table>
		<div class="rowInsertButton">
			<b-button @click="isRowInsert">{{$t('행 추가')}}</b-button>
		</div>
	</div>
</template>

<script>
	import * as rules from "@src/consts/ruleConsts.js";
	import * as validMessage from "@src/consts/validMessageConsts.js";

	export default {
		name: "TypePropertyDetailTable",
		props: ["items", "equipTypeCode", "ctrlOptList"],
		data() {
			return {
				tableField: null,
				// tableData: null,
				tableCheckbox: [],
				tableUsegeOptions: [
					{ value: "Y", text: global.xe.$t("사용") },
					{ value: "N", text: global.xe.$t("사용안함") },
				],
				rules,
				validMessage,
			};
		},
		computed: {
			tableData() {
				let tables = this.items;
				if (!tables.errno) {
					// for (let i = 0; i < tables.length; i++) {
					// 	tables[i].checked = false;
					// }
					return tables;
				} else {
					return [];
				}
			},
			ctrlOptions() {
				let options = [];

				for (let i = 0; i < this.ctrlOptList.length; i++) {
					options.push({
						value: this.ctrlOptList[i].smallCode,
						text: this.ctrlOptList[i].smallCodeName,
					});
				}
				return options;
			},
		},
		created() {
			this.setTableField();
			// this.setTableItems();
		},
		methods: {
			setTableField() {
				this.tableField = [
					{ key: "checked", label: "", tdClass: "typeDetailChecked" },
					{ key: "equipPropCode", label: global.xe.$t("항목 코드"), tdClass: "typeDetailPropCode" },
					{ key: "equipPropName", label: global.xe.$t("항목명"), tdClass: "typeDetailPropName" },
					{ key: "ctrlOptCode", label: global.xe.$t("제어 옵션"), tdClass: "typeDetailControl" },
					{ key: "enabled", label: global.xe.$t("사용여부"), tdClass: "typeDetailCEnabled" },
					{ key: "sortOrder", label: global.xe.$t("정렬 순서"), tdClass: "typeDetailSortOrder" },
					{ key: "propDesc", label: global.xe.$t("비고"), tdClass: "typeDetailPropDesc" },
				];
			},

			onRowDetail(row) {
				row.checked = !row.checked;
			},
			isCheckService(value, row) {
				if (value === true) {
					this.$refs.TypePropertyDetailTable.selectRow(row.index);
				} else if (value === false) {
					this.$refs.TypePropertyDetailTable.unselectRow(row.index);
				}
			},
			isRowInsert() {
				this.tableData.push({
					isNew: true,
					checked: true,
					isServer: false,
					ctrlOptCode: "",
					equipTypeCode: this.equipTypeCode,
					equipPropCode: "",
					equipPropName: "",
					enabled: "",
					sortOrder: "",
					propDesc: "",
					unitLargeCode: "",
					unitSmallCode: "",
				});

				if (!Array.isArray(this.emptyData)) {
					this.$emit("firstInput", this.tableData);
					this.$refs.TypePropertyDetailTable.refresh();
				}
			},
		},
	};
</script>

<style lang="scss">
	.TypePropertyDetailTable {
		.table {
			.typeDetailChecked {
				text-align-last: right;
			}

			.typeDetailPropCode {
				width: 15%;
				min-width: 100px;
			}

			.typeDetailControl {
				width: 13%;
				min-width: 100px;
			}

			.typeDetailPropName {
				width: 15%;
				min-width: 150px;
			}

			.typeDetailCEnabled {
				width: 12%;
				min-width: 100px;
			}

			.typeDetailSortOrder {
				width: 10%;
				min-width: 65px;
				text-align-last: center;
			}

			.typeDetailPropDesc {
				width: 30%;
				min-width: 100px;
			}

			td {
				vertical-align: middle;
			}
		}

		.rowInsertButton {
			text-align: center;
			margin-top: 10px;
		}
	}
</style>
