<template>
	<div class="col-xl-12">
		<div class="row">
			<div class="col-lg-12">
				<div class="TypePropertyDetail bg-white">
					<div class="TypePropertyDetailTitle">
						□ {{$t('설비별 수집항목')}}
					</div>
					<div class="TypePropertyDetailTable" v-if="data !== null && propertys !== null">
						<vvo v-slot="v">
        
							<div>
								<div class="row">
									<b-col cols="4">
										<b-form-group label-cols="4" label-cols-lg="4" content-cols="8" content-cols-lg="8" :label="$t('설비 유형')" label-for="input-default">
											<b-form-input v-model="data.equipTypeCodeName" disabled> </b-form-input>
										</b-form-group>
									</b-col>
									<b-col cols="4"> </b-col>
									<b-col cols="4">
										<typePropertyDetailBtn @update="onValidate(v, onModify, propertys)" @delete="onRemove"></typePropertyDetailBtn>
									</b-col>
								</div>
								<div>
									<type-property-detail-table :items="propertys" :equipTypeCode="data.equipTypeCode" :ctrlOptList="ctrlOptList" @firstInput="firstProperty"></type-property-detail-table>
								</div>
							</div>
						</vvo>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import backEndApi from "../../../api/backEndApi";

	import typePropertyDetailTable from "./TypePropertyDetailTable.vue";
	import typePropertyDetailBtn from "./TypePropertyDetailBtn.vue";
	import AppErrorHandler from "@src/appErrorHandler";
	import * as popupMessages from "@src/consts/popupMessageConsts";
	import { StatusCodes } from "http-status-codes";

	export default {
		name: "TypePropertyDetail",
		props: ["data"],
		components: {
			typePropertyDetailTable,
			typePropertyDetailBtn,
		},
		data() {
			return {
				propertys: null,
				originPropertys: null,
				selectTypeCodeInfo: {
					equipTypeCode: "",
					enabled: "",
				},
				ctrlOptList: [],
				ctrlCodeList: [],
				popupMessages,
				checkedPropertys: [],
			};
		},
		created() {
			this.getCtrlOptCode();
		},
		watch: {
			async data(newVal, oldVal) {
				if (oldVal === null || newVal.equipTypeCode !== oldVal.equipTypeCode) {
					await this.getPropertyData(newVal);
				}
			},
		},
		methods: {
			checkPropertys() {
				let checkedPropertys = [];

				for (let i = 0; i < this.propertys.length; i++) {
					let property = this.propertys[i];
					console.log(property);

					if (property.checked === true) checkedPropertys.push(property);
				}

        return checkedPropertys;
			},

			async getCtrlOptCode() {
				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
        ↓↓↓↓↓
      */
				try {
					let result = await backEndApi.publicCode.getSmallCodeList("ctrlOptCode", null, "Y");
					this.ctrlOptList = result.data;
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			},

			async getPropertyData(param) {
				this.selectTypeCodeInfo = {
					equipTypeCode: param.equipTypeCode,
				};
				// for (let i = 0; i < this.ctrlOptList.length; i++) {
				//   this.ctrlCodeList.push(this.ctrlOptList[i].smallCode);

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
        ↓↓↓↓↓
      */
				try {
					let res = await backEndApi.equipType.getEquipPropList(param.equipTypeCode);
					this.propertys = res.data;
					for (let i = 0; i < this.propertys.length; i++) {
						this.propertys[i]["isServer"] = true;
					}
					this.originPropertys = JSON.parse(JSON.stringify(this.propertys));
				} catch (err) {
					new AppErrorHandler(err)
						.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
						.createApiHandle(StatusCodes.INTERNAL_SERVER_ERROR)
						.createBizHandle(this.ERR_KIND.MISSING_DATA, popupMessages.EQUIP_CATEGORY_REGIST_FAIL_POPUP_MESSAGE, this.alertNoti)
						.errHandling();
					this.propertys = [];
				}

				return;
				// }

				// backEndApi.equipType.getEquipPropList(param.equipTypeCode).then(({ data }) => {
				//   result = data;
				// });

				// this.propertys = properties;

				// this.originPropertys = JSON.parse(JSON.stringify(this.propertys));
			},
			async onModify() {
				if (this.propertys.errno) {
					this.alertNoti(popupMessages.EQUIP_CATEGORY_REGIST_FAIL_POPUP_MESSAGE);
					return;
				}

				for (let i = 0; i < this.propertys.length; i++) {
					let property = this.propertys[i];
					if (property.checked === false) continue;

					property = {
						equipTypeCode: property.equipTypeCode,
						equipPropCode: property.equipPropCode,
						equipPropName: property.equipPropName,
						ctrlOptCode: property.ctrlOptCode,
						enabled: property.enabled,
						sortOrder: property.sortOrder,
						unitLargeCode: property.unitLargeCode,
						unitSmallCode: property.unitSmallCode,
						propDesc: property.propDesc,
					};
					let propertyKeys = Object.keys(property);

					for (let j = 0; j < propertyKeys.length; j++) {
						let keys = propertyKeys[j];
						if (keys !== "unitLargeCode" && keys !== "unitSmallCode") {
							if (property[keys].toString().trim() === "" || property[keys] === null) {
								switch (keys) {
									// case "equipPropCode": {
									//   this.alertNoti(popupMessages.EQUIP_COLLECT_CODE_VALID_POPUP_MESSAGE);
									//   return;
									// }
									// case "equipPropName": {
									//   this.alertWarning(popupMessages.EQUIP_COLLECT_NAME_VALID_POPUP_MESSAGE);
									//   return;
									// }
									case "ctrlOptCode": {
										this.alertWarning(popupMessages.EQUIP_COLLECT_CONTROL_VALID_POPUP_MESSAGE);
										return;
									}
									case "enabled": {
										this.alertWarning(popupMessages.COMMON_USAGE_VALID_POPUP_MESSAGE);
										return;
									}
									// case "sortOrder": {
									//   this.alertNoti(popupMessages.EQUIP_COLLECT_SORTING_VALID_POPUP_MESSAGE);
									//   return;
									// }
								}
							}
						}
					}
				}

				let param = [];

				for (let i = 0; i < this.propertys.length; i++) {
					if (this.propertys[i].checked === false) continue;

					param.push({
						equipTypeCode: this.propertys[i].equipTypeCode,
						equipPropCode: this.propertys[i].equipPropCode,
						equipPropName: this.propertys[i].equipPropName,
						ctrlOptCode: this.propertys[i].ctrlOptCode,
						enabled: this.propertys[i].enabled,
						sortOrder: this.propertys[i].sortOrder,
						unitLargeCode: this.propertys[i].unitLargeCode === null ? " " : this.propertys[i].unitLargeCode,
						unitSmallCode: this.propertys[i].unitSmallCode === null ? " " : this.propertys[i].unitSmallCode,
						propDesc: this.propertys[i].propDesc,
					});
				}

				if (param.length <= 0) {
					this.alertDanger(popupMessages.COMMON_SAVE_MODIFY_NO_DATA_POPUP_MESSAGE);
					return;
				}

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
        ↓↓↓↓↓
      */
				try {
					await backEndApi.equipType.insertEquipTypeProps(param);
					this.alertNoti(popupMessages.COMMON_SAVE_POPUP_MESSAGE);
					await this.getPropertyData(this.selectTypeCodeInfo);
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			},
			async onRemove() {
				let isErrorProperty = JSON.parse(JSON.stringify(this.propertys));
				let checkProperty = [];
				let checkPropertyIndex = [];
				let originIndex = 0;
				for (let i = 0; i < this.propertys.length; i++) {
					if (this.propertys[i].checked === true) {
						checkProperty.push(this.propertys[i]);
						checkPropertyIndex.push(i + originIndex);
						this.propertys.splice(i, 1);
						i--;
						originIndex++;
					}
				}
				console.log(checkProperty, checkPropertyIndex);
				if (checkProperty.length === 0) {
					this.alertNoti(popupMessages.EQUIP_COLLECT_NO_DELETE_VALID_POPUP_MESSAGE);
					return;
				}

				let removeParam = [];

				for (let i = 0; i < checkPropertyIndex.length; i++) {
					let idx = checkPropertyIndex[i];
					let isParam = this.findParameterData(idx);
					if (isParam) {
						removeParam.push({
							equipTypeCode: this.originPropertys[idx].equipTypeCode,
							equipPropCode: this.originPropertys[idx].equipPropCode,
							equipPropName: this.originPropertys[idx].equipPropName,
							ctrlOptCode: this.originPropertys[idx].ctrlOptCode,
							enabled: this.originPropertys[idx].enabled,
							sortOrder: this.originPropertys[idx].sortOrder,
							unitLargeCode: this.originPropertys[idx].unitLargeCode,
							unitSmallCode: this.originPropertys[idx].unitSmallCode,
							propDesc: this.originPropertys[idx].propDesc,
						});
					}
				}
				console.log(removeParam);
				if (removeParam.length === 0) {
					this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
					return;
				}

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
        ↓↓↓↓↓
      */
				try {
					await backEndApi.equipType.removeEquipTypeProps(removeParam);
					this.alertNoti(popupMessages.COMMON_DELETE_POPUP_MESSAGE);
					let temp = JSON.parse(JSON.stringify(this.propertys));
					await this.getPropertyData(this.selectTypeCodeInfo);
					this.propertys = temp;
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
					this.propertys = isErrorProperty;
				}
			},
			findParameterData(idx) {
				let value = this.originPropertys[idx];
				if (value) {
					return true;
				} else {
					return false;
				}
			},
			firstProperty(value) {
				this.propertys = value;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.TypePropertyDetail {
		border: 1px solid #313131;
		border-radius: 5px;

		.TypePropertyDetailTitle {
			padding: 15px;
			font-size: 15px;
			font-weight: bold;
		}

		.TypePropertyDetailTable {
			padding: 15px;
			margin: 5px;
			border: 1px solid #000000;
		}
	}
</style>
