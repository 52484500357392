<template>
	<b-table
		hover
		bordered
		head-variant="dark"
		no-border-collapse
		show-empty
		sticky-header
		ref="selectableTable"
		:class="'text-center bg-white'"
		:items="items"
		:fields="fields"
		:empty-text="$t('조회된 결과가 없습니다.')"
		:select-mode="'single'"
		selectable
		@row-clicked="onRowDetail"
	>
		<template #empty="scope">
			<h4>{{ scope.emptyText }}</h4>
		</template>

		<template #cell(selected)="{ rowSelected }">
			<template v-if="rowSelected">
				<span>&check;</span>
				<span class="sr-only">Selected</span>
			</template>
			<template v-else>
				<span>&nbsp;</span>
				<span class="sr-only">Not selected</span>
			</template>
		</template>

		<template #cell(index)="data">
			{{ data.index + 1 }}
		</template>
	</b-table>
</template>

<style scoped>
	.b-table-sticky-header {
		max-height: 760px;
	}
</style>

<script>
	// import detailCard from "./GridCard.vue";

	export default {
		components: {},
		props: ["data"],
		data() {
			return {
				fields: null,
				items: null,
				emptyText: null,
				selectedRow: [],
			};
		},
		watch: {
			data() {
				this.selectedRow._showDetails = !this.selectedRow._showDetails;
				this.setItems();
			},
		},
		created() {
			this.setFields();
			this.setItems();
		},
		mounted() {},
		methods: {
			onRowDetail(row) {
				this.$emit("selectType", row);
			},
			clearSeclected() {
				this.$refs.selectableTable.clearSelected();
			},
			setFields() {
				this.fields = [
					{ key: "index", label: global.xe.$t("순번") },
					{ key: "equipTypeCodeName", label: global.xe.$t("설비 유형명") },
					{ key: "enabled", label: global.xe.$t("사용여부") },
				];
			},
			setItems() {
				this.items = this.data;
			},
			onModify() {
				this.$refs.detail.onModify();
			},
			onRemove() {
				this.$refs.detail.onRemove();
			},
			onReset() {
				this.$refs.detail.onReset();
			},
		},
	};
</script>
